import React, { FC, ReactNode } from 'react';

import s from './Header.module.scss';

interface HeaderProps {
  children: string | ReactNode;
  whiteText?: boolean;
  isMainHeader?: boolean;
}

export const Header: FC<HeaderProps> = ({ children, whiteText, isMainHeader}) => {

  const renderHeader = () => {
    if (isMainHeader) {
      return <h1 className={`${s.header} ${ whiteText ? s.white :'' }`}> {children} </h1>
    } else {
      return <h2 className={`${s.header} ${ whiteText ? s.white :'' }`}> {children} </h2>
    }
  };

  return renderHeader();
};
