import React from 'react';

import {GatsbySeo} from 'gatsby-plugin-next-seo';
import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';
import {Header} from '@components/header/Header.component';
import {Button} from '@components/button/Button';

import { useIntl } from 'gatsby-plugin-intl';

import s from '../app/ebook/Ebook.module.scss';

// tslint:disable no-default-export
export default () => {

  const sendDataToGTM = useGTMDispatch();
  const intl = useIntl();

  return (
    <>
      <GatsbySeo
        nofollow={true}
        noindex={true}
        title={`Avionaut - Pobierz E-book`}
      />
      <div className={s.ebook}>
        <Header>Dziękujemy za udział w wydarzeniu!</Header>
        <div className={s.ebook__text}>
          <strong style={{marginBottom: '50px'}}>Cieszymy się, że mogliśmy tam być RAZEM!</strong>
          <br/>
          Z tej okazji przygotowaliśmy dla Ciebie bezpłatnego e-booka, który ułatwi Ci stworzenie praktycznej wyprawki.
          <br/>
          <br/>
          Każdy rodzic ma prawo czuć się przytłoczony ilością rzeczy, którą potrzebuje mały człowiek, zanim jeszcze
          przyjdzie na świat.
          <br/>
          Warto jednak dokładnie się do tego zadania przygotować, aby przypadkiem nie zapomnieć o czymś istotnym bądź
          nie
          zakupić niepotrzebnych produktów.
          <br/>
          Aby ułatwić rodzicom kompletowanie wyprawki, przygotowaliśmy bezpłatny e-book, który powstał wraz z
          certyfikowaną położną.
          <br/>
          <br/>
          Już teraz pobierz e-book, który pomoże Ci zaplanować wyprawkę oraz podróże z małym pasażerem!
          <br/>
          <br/>
          <Button
            onClick={() => {
              sendDataToGTM({
                'event': 'ebook',
                'event_category': 'ebook_pl',
                'event_label': 'button',
                'event_language': intl.locale,
              })
            }}
            href={'https://media.avionaut.com/ebook/niezbednikmlodychrodzicow.pdf'}>
            Pobierz E-book
          </Button>
        </div>
      </div>
    </>
  );
};
